.rangePicker {
  &.ant-picker-dropdown {
    margin-top: 15px !important;
  }

  & .ant-picker-date-range-wrapper {
    right: 23px !important;
  }

  & .ant-picker-panel-container {
    border-radius: 16px !important;
    margin: 0px !important;
  }

  &.ant-picker-dropdown-range {
    padding-top: 25px !important;
  }

  .ant-picker-panels > *:last-child {
    display: none !important;
  }

  .ant-picker-range-arrow {
    display: none !important;
  }

  .ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
    visibility: visible !important;
  }

  .ant-picker-panels > *:first-child button.ant-picker-header-super-prev-btn {
    visibility: hidden !important;
  }

  .ant-picker-panels > *:last-child {
    display: none;
  }

  .ant-picker-panel-container,
  .ant-picker-footer {
    width: 255px !important;
  }

  .ant-picker-footer-extra > div {
    flex-wrap: wrap !important;
  }

  & .ant-picker-cell-in-view {
    width: 25px !important;
    height: 20px !important;
    color: #384a78;
    border: none !important;

    &:hover {
      & > ::after {
        width: 32px !important;
      }
    }
  }

  &
    .ant-picker-cell-in-view.ant-picker-cell-range-start:not(
      .ant-picker-cell-range-start-single
    )::before,
  & .ant-picker-cell-in-view.ant-picker-cell-in-range::before,
  &
    .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
      .ant-picker-cell-range-end-single
    )::before,
  &
    .ant-picker-time-panel-column
    > li.ant-picker-time-panel-cell-selected
    .ant-picker-time-panel-cell-inner {
    background: #00a3ff1a !important;
    height: 20px !important;
  }

  & .ant-picker-cell-inner {
    width: 25px !important;
    height: 20px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    font-family: 'Inter', sans-serif;
    border: none !important;
  }

  &
    .ant-picker-cell-in-view.ant-picker-cell-range-start
    .ant-picker-cell-inner {
    background-color: #0058d0 !important;
    border-radius: 4px !important;
  }

  & .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background-color: #0058d0 !important;
    border-radius: 4px !important;
  }

  &
    .ant-picker-cell-in-view.ant-picker-cell-today
    .ant-picker-cell-inner::before {
    border-color: #0058d0 !important;
    border-radius: 4px !important;
  }

  & .ant-picker-dropdown .ant-picker-cell {
    width: 25px !important;
    border-radius: 4px !important;
    height: 20px !important;
    font-size: 10px !important;
    display: flex;
    justify-content: center;
    vertical-align: center;
  }

  & .ant-picker-month-btn,
  .ant-picker-year-btn {
    pointer-events: none;
  }

  & .ant-picker-cell-disabled.ant-picker-cell-in-view,
  .ant-picker-cell-disabled {
    color: #d0d0d4 !important;
  }

  & .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-hover,
  .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-hover-start,
  .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-hover-end {
    &::after {
      max-height: 20px !important;
      // max-width: 25px !important;
    }
  }

  & .ant-picker-content {
    width: 223px !important;

    thead {
      tr {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
      }
      th {
        width: 25px !important;
        height: 20px !important;
        box-sizing: border-box;
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        color: #384a78;
      }
    }
  }

  & .ant-picker-date-panel {
    width: 255px !important;
  }

  & .ant-picker-date-panel .ant-picker-body {
    padding: 10px 16px 10px 18px !important;
    box-sizing: border-box !important;
    width: 255px !important;
  }

  & .ant-picker-header {
    border-bottom: 1px solid rgba(83, 154, 194, 0.2);

    & .ant-picker-prev-icon,
    .ant-picker-next-icon {
      &::before {
        border-color: #7e97b8;
        border-block-start-width: 2px;
        border-inline-start-width: 2px;
      }
    }

    & .ant-picker-header-view {
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 700;
      color: #384a78;

      & button {
        font-size: 10px;
      }

      & > :last-child {
        margin-inline-start: 3px;
      }
    }
  }

  & .ant-picker-cell.ant-picker-cell-disabled.ant-picker-cell-in-view {
    &::before {
      background: transparent !important;
    }
  }

  & .ant-picker-cell.ant-picker-cell-disabled {
    &::before {
      background: transparent !important;
    }
  }

  & .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-hover-end,
  .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-hover-start {
    &:hover {
      & > ::after {
        width: 25px !important;
        border: none !important;
      }
    }
    & .ant-picker-cell-inner::after {
      left: 50% !important;
      transform: translateX(-50%);
    }
  }
}
