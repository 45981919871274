@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Golos+Text:wght@400;500;600;700;800;900&family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;
  font-family: 'Golos Text', 'Inter', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-picker-panel-container {
  box-shadow: 0px 4px 32px 2px rgba(10, 83, 182, 0.12) !important;
}

.ant-notification {
  margin: 0;
}

.ant-notification-notice-close {
  display: none !important;
}

.ant-notification-notice-message {
  margin-bottom: 0 !important;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px !important;
  padding-inline-end: 0px !important;
}

.ant-notification-notice {
  box-shadow: none;
  box-sizing: border-box;
}

.tooltip-text {
  font-family: 'Noto Sans', 'Inter', sans-serif;
  font-weight: 500;
  font-size: 10px;
  line-height: 20px;
  white-space: nowrap;
  word-wrap: normal;
}
